<template>
  <basic-tab :index="7" title="Cảm xúc">
    <template v-slot:tab-title>
      <b-row align-h="start" class="mt-10 mb-10 font-weight-bold text-dark">
        <b-col>
          <h4>Danh sách cảm xúc của bệnh nhân</h4>
        </b-col>
      </b-row>
    </template>
    <b-row class="pb-8">
      <b-col>
        <template-table
          :column="column"
          :data="data"
          :paging="paging"
          :tableAction="false"
          :selectAction="false"
        >
          <template v-slot:form>
            <div class="row mb-4">
              <div class="col-lg-3 mb-lg-0 mb-0">
                <label>Tìm kiếm:</label>
                <b-input
                  type="text"
                  class="form-control datatable-input"
                  placeholder="Nhập mã, tên bệnh nhân"
                  data-col-index="0"
                  v-model="filter.searchKey"
                />
              </div>
              <div class="col-lg-3 mb-lg-0 mb-4">
                <label>Ngày bắt đầu:</label>
                <datepicker
                  placeholder="Ngày bắt đầu"
                  v-model="filter.startedDatetime"
                  format="DD-MM-YYYY"
                  value-type="timestamp"
                ></datepicker>
              </div>
              <div class="col-lg-3 mb-lg-0 mb-4">
                <label>Ngày kết thúc:</label>
                <datepicker
                  placeholder="Ngày bắt đầu"
                  v-model="filter.endedDatetime"
                  format="DD-MM-YYYY"
                  value-type="timestamp"
                ></datepicker>
              </div>
            </div>
          </template>
          <template v-slot:body="{ item }">
            <td>
              {{ item.account.firstName }}
            </td>
            <td>
              {{ item.date }}
            </td>
            <!-- <td>{{ item.hbA1cAverage }}</td>
            <td>{{ item.meanBloodAverage }}</td>
            <td>{{ item.glucoseAverage }}</td> -->
          </template>
        </template-table>
      </b-col>
    </b-row>
  </basic-tab>
</template>

<script>
export default {
  data() {
    return {
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      column: [
        {
          key: 'name',
          label: 'Tên bệnh nhân',
          sortable: false,
        },
        {
          key: 'date',
          label: 'Ngày nhập',
          sortable: false,
        },
        // {
        //   key: 'hbA1cAverage',
        //   label: 'HbA1c trung bình',
        //   sortable: false,
        // },
        // {
        //   key: 'meanBloodAverage',
        //   label: 'Máu trung bình',
        //   sortable: false,
        // },
        // {
        //   key: 'glucoseAverage',
        //   label: 'Đường trung bình',
        //   sortable: false,
        // },
      ],
      data: [],
      filter: {
        searchKey: null,
        startedDatetime: null,
        endedDatetime: null,
      },
    };
  },
  computed: {
    searchParams() {
      return {
        searchKey: this.filter.searchKey,
        page: this.paging.page,
        size: this.paging.pageSize,
      };
    },
  },
  watch: {},
  methods: {
    loadData() {
      this.$store.commit('context/setLoading', true);
      this.$api
        .get('emotionStatistic', {
          params: this.searchParams,
        })
        .then(({ meta, data }) => {
          this.data = data || [];
          this.paging.total = meta.total;
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error,
          });
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
      return;
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
